<template>
  <div class="usercenter">
    <div class="user_header">
      <div class="w1">
        <div class="ft_logo"></div>
        <div class="user_name fr">
          <div class="name clearfix">
            <span class="ftrenwu_icon"> <img :src="imgSrc" alt="" /> </span
            ><span class="userName">{{ userName }}</span>
            <span
              class="passIconfont ftdengchu login_out1 icon-ftdengchu loginOut"
              @click="loginout"
            ></span>
            <!-- <div class="login_out">登出</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="userInfo">
      <div class="Info_item">
        <div class="title">账号信息</div>
        <li class="user_info_item">
          <span class="user_info_label">账号名</span
          ><span class="user_info_value">{{ name }}</span>
        </li>
        <li class="user_info_item">
          <span class="user_info_label">UUID</span
          ><span class="user_info_value">{{ uuid }}</span>
        </li>
        <li class="user_info_item">
          <span class="user_info_label">邮箱</span
          ><span class="user_info_value">{{ email }}</span>
          <!-- <span class="user_info_btn fr">绑定邮箱</span> -->
        </li>
      </div>
    </div>
    <div class="userInfo userContro">
      <div class="Info_item">
        <div class="title">账号安全设置</div>
        <li class="user_contro_item">
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/images/email_icon@2x.png" alt="" />
            </div>
            <div class="icon_info">
              <p class="icon_name">邮箱</p>
              <!-- <p class="icon_status"></p> -->
              <router-link to="/bindEmail">
                <button
                  class="bind_btn"
                  :class="emailFlag ? 'bind_btn_no' : 'bind_btn_yes'"
                  :disabled="emailFlag"
                >
                  {{ emailFlag ? "已绑定" : "绑定" }}
                </button>
              </router-link>
            </div>
          </div>
          <div class="icon_item">
            <div class="icon">
              <img src="../../assets/images/change@2x.png" alt="" />
            </div>
            <div class="icon_info">
              <p class="icon_name">修改密码</p>
              <!-- <p class="icon_status"></p> -->
              <router-link to="/findAccountGuide">
                <button class="bind_btn bind_btn_yes">修改</button></router-link
              >
            </div>
          </div>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLocalStorage,
  setLocalStorage,
  getToken,
  removeToken,
  removesessionStorage,
  removeLocalStorage,
} from "@/utils/stage";
export default {
  data() {
    return {
      name: "",
      email: "",
      uuid: "",
      userName: "",
      emailFlag: true,
      imgSrc: require("@/assets/images/google_icon.png"),
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const data = JSON.parse(getLocalStorage("accountStorage"));
      if (getToken("login") != "success") {
        this.$router.push("/");
      }
      //   判断登陆的方式
      if (data.channel == 4) {
        // fb
        this.userName = data.username;
        this.imgSrc = require("@/assets/images/fb_icon.png");
      } else if (data.channel == 5) {
        // google
        this.userName = data.nickname;
        this.imgSrc = require("@/assets/images/google_icon.png");
      } else if (data.channel == 3) {
        //apple
        this.userName = data.nickname;
        this.imgSrc = require("@/assets/images/apple_icon.png");
      } else if (data.channel == null) {
        //   this.email
        this.userName = data.email;
        this.imgSrc = require("@/assets/images/email_icon.png");
      }
      if (data) {
        this.name = data.username;
        this.uuid = data.open_id;
        if (data.email) {
          this.email = data.email;
        } else {
          this.email = "-";
          this.emailFlag = false;
        }
      } else {
        this.$router.push("/");
      }
    },
    loginout() {
      removeToken("login");
      removesessionStorage("accountStorage");
      removeLocalStorage("accountStorage");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 1600px) {
  .usercenter {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    .w1 {
      width: 1100px;
      margin: 0 auto;
    }
    .user_header {
      width: 100%;
      height: 66px;
      // background: #ff7391;
      background: #fff;
      .ft_logo {
        width: 166px;
        height: 66px;
        background: url("../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
      }
      .user_name {
        width: 210px;
        height: 66px;
        padding: 0px 10px;
        //   box-sizing: border-box;
        margin-top: -66px;
        line-height: 80px;
        font-size: 20px;
        color: #fff;
        text-align: center;
      }
      .name {
        width: 100%;
        height: 100%;
        position: relative;
        .ftrenwu_icon {
          float: left;
          font-size: 25px;
          color: #ccc;
          margin-left: 10px;
          width: 45px;
          margin-top: 15px;
        }
        .userName {
          display: inline-block;
          width: 90px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333;
        }
        .loginOut {
          float: right;
          color: #333;
          font-size: 25px;
          cursor: pointer;
          margin-right: 10px;
        }
        .login_out {
          width: 210px;
          height: 50px;
          position: absolute;
          background: #fff;
          line-height: 50px;
          top: 66px;
          display: none;
        }
        &:hover {
          border-bottom: 5px;
          .login_out {
            display: block;
            color: #000;
          }
        }
      }
    }
    .userInfo {
      width: 1000px;
      margin: 0 auto;
      margin-top: 50px;
      .Info_item {
        background: #fff;
        min-height: 300px;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 0 30px;
        .title {
          height: 60px;
          font-size: 20px;
          border-bottom: 1px solid #e6e6e6;
          line-height: 60px;
          margin-bottom: 20px;
        }
        .title_item {
        }
        .user_info_item {
          width: 100%;
          // margin-top: 20px;
          padding: 14px 0;
          font-size: 18px;
          color: #333;
          .user_info_label {
            width: 150px;
            display: inline-block;
            text-align: left;
          }
        }
        .user_info_btn {
          width: 110px;
          height: 36px;
          display: inline-block;
          line-height: 36px;
          text-align: center;
          font-size: 16px;
          border: 1px solid #e6e6e6;
          border-radius: 6px;
          cursor: pointer;
          &:hover {
            border: 1px solid #ff7391;
            color: #ff7391;
          }
        }
        .user_contro_item {
          margin-top: 10px;
          .icon_item {
            //   position: relative;
            display: inline-flex;
            width: 200px;
            height: 110px;

            .icon {
              width: 70px;
              height: 70px;
              margin-top: 7px;
            }
            .icon_info {
              width: 70px;
              padding-left: 10px;
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              .icon_name {
                font-size: 16px;
                margin-top: 5px;
                margin-bottom: 15px;
              }
              .bind_btn {
                width: 75px;
                height: 26px;
                font-size: 13px;
                padding: 3px 5px;
                background: none;
                outline: none;
                border: 1px solid #e6e6e6;
                border-radius: 5px;
                color: #4ea4dc;
                line-height: 20px;
                cursor: pointer;
              }
              .bind_btn_yes {
                &:hover {
                  border: 1px solid #ff7391;
                  color: #ff7391;
                }
              }
              .bind_btn_no {
                background-color: #ccc;
                cursor: not-allowed;
              }
              // position: absolute;
              // top: 0;
              // left: 75px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .usercenter {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    .w1 {
      width: 1100 * 0.85px;
      margin: 0 auto;
    }
    .user_header {
      width: 100%;
      height: 66 * 0.85px;
      // background: #ff7391;
      background: #fff;
      .ft_logo {
        width: 166 * 0.85px;
        height: 66 * 0.85px;
        background: url("../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
      }
      .user_name {
        width: 210 * 0.85px;
        height: 66 * 0.85px;
        padding: 0 * 0.85px 10 * 0.85px;
        //   box-sizing: border-box;
        margin-top: -66 * 0.85px;
        line-height: 80 * 0.85px;
        font-size: 20 * 0.85px;
        color: #fff;
        text-align: center;
      }
      .name {
        width: 100%;
        height: 100%;
        position: relative;
        .ftrenwu_icon {
          float: left;
          font-size: 25 * 0.85px;
          color: #ccc;
          margin-left: 10 * 0.85px;
          width: 45 * 0.85px;
          margin-top: 15 * 0.85px;
        }
        .userName {
          display: inline-block;
          width: 90 * 0.85px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333;
        }
        .loginOut {
          float: right;
          color: #333;
          cursor: pointer;
          font-size: 25 * 0.85px;
          margin-right: 10 * 0.85px;
        }
        .login_out {
          width: 210 * 0.85px;
          height: 50 * 0.85px;
          position: absolute;
          background: #fff;
          line-height: 50 * 0.85px;
          top: 66 * 0.85px;
          display: none;
        }
        &:hover {
          border-bottom: 5 * 0.85px;
          .login_out {
            display: block;
            color: #000;
          }
        }
      }
    }
    .userInfo {
      width: 1000 * 0.85px;
      margin: 0 auto;
      margin-top: 50 * 0.85px;
      .Info_item {
        background: #fff;
        min-height: 300 * 0.85px;
        border: 1 * 0.85px solid #ccc;
        border-radius: 10 * 0.85px;
        padding: 0 30 * 0.85px;
        .title {
          height: 60 * 0.85px;
          font-size: 20 * 0.85px;
          border-bottom: 1 * 0.85px solid #e6e6e6;
          line-height: 60 * 0.85px;
          margin-bottom: 20 * 0.85px;
        }
        .title_item {
        }
        .user_info_item {
          width: 100%;
          // margin-top: 20*0.85px;
          padding: 14 * 0.85px 0;
          font-size: 18 * 0.85px;
          color: #333;
          .user_info_label {
            width: 150 * 0.85px;
            display: inline-block;
            text-align: left;
          }
        }
        .user_info_btn {
          width: 110 * 0.85px;
          height: 36 * 0.85px;
          display: inline-block;
          line-height: 36 * 0.85px;
          text-align: center;
          font-size: 16 * 0.85px;
          border: 1 * 0.85px solid #e6e6e6;
          border-radius: 6 * 0.85px;
          cursor: pointer;
          &:hover {
            border: 1 * 0.85px solid #ff7391;
            color: #ff7391;
          }
        }
        .user_contro_item {
          margin-top: 10 * 0.85px;
          .icon_item {
            //   position: relative;
            display: inline-flex;
            width: 200 * 0.85px;
            height: 110 * 0.85px;

            .icon {
              width: 70 * 0.85px;
              height: 70 * 0.85px;
              margin-top: 7 * 0.85px;
            }
            .icon_info {
              width: 70 * 0.85px;
              padding-left: 10 * 0.85px;
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              .icon_name {
                font-size: 16 * 0.85px;
                margin-top: 5 * 0.85px;
                margin-bottom: 15 * 0.85px;
              }
              .bind_btn {
                width: 75 * 0.85px;
                height: 26 * 0.85px;
                font-size: 13 * 0.85px;
                padding: 3 * 0.85px 5 * 0.85px;
                background: none;
                outline: none;
                border: 1 * 0.85px solid #e6e6e6;
                border-radius: 5 * 0.85px;
                color: #4ea4dc;
                line-height: 20 * 0.85px;
                cursor: pointer;
              }
              .bind_btn_yes {
                &:hover {
                  border: 1 * 0.85px solid #ff7391;
                  color: #ff7391;
                }
              }
              .bind_btn_no {
                background-color: #ccc;
                cursor: not-allowed;
              }
              // position: absolute;
              // top: 0;
              // left: 75*0.85px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 500px) and (max-width: 1199px) {
  .usercenter {
    width: 100%;
    height: 100vh;
    background: #f1f1f1;
    .w1 {
      width: 1100 * 0.6px;
      margin: 0 auto;
    }
    .user_header {
      width: 100%;
      height: 66 * 0.6px;
      // background: #ff7391;
      background: #fff;
      .ft_logo {
        width: 166 * 0.6px;
        height: 66 * 0.6px;
        background: url("../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
      }
      .user_name {
        width: 210 * 0.6px;
        height: 66 * 0.6px;
        padding: 0 * 0.6px 10 * 0.6px;
        //   box-sizing: border-box;
        margin-top: -66 * 0.6px;
        line-height: 80 * 0.6px;
        font-size: 20 * 0.6px;
        color: #fff;
        text-align: center;
      }
      .name {
        width: 100%;
        height: 100%;
        position: relative;
        .ftrenwu_icon {
          float: left;
          font-size: 25 * 0.6px;
          color: #ccc;
          margin-left: 10 * 0.6px;
          width: 45 * 0.6px;
          margin-top: 15 * 0.6px;
        }
        .userName {
          display: inline-block;
          width: 90 * 0.6px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333;
        }
        .loginOut {
          float: right;
          color: #333;
          font-size: 25 * 0.6px;
          margin-right: 10 * 0.6px;
          cursor: pointer;
        }
        .login_out {
          width: 210 * 0.6px;
          height: 50 * 0.6px;
          position: absolute;
          background: #fff;
          line-height: 50 * 0.6px;
          top: 66 * 0.6px;
          display: none;
        }
        &:hover {
          border-bottom: 5 * 0.6px;
          .login_out {
            display: block;
            color: #000;
          }
        }
      }
    }
    .userInfo {
      width: 1000 * 0.6px;
      margin: 0 auto;
      margin-top: 50 * 0.6px;
      .Info_item {
        background: #fff;
        min-height: 300 * 0.6px;
        border: 1 * 0.6px solid #ccc;
        border-radius: 10 * 0.6px;
        padding: 0 30 * 0.6px;
        .title {
          height: 60 * 0.6px;
          font-size: 20 * 0.6px;
          border-bottom: 1 * 0.6px solid #e6e6e6;
          line-height: 60 * 0.6px;
          margin-bottom: 20 * 0.6px;
        }
        .title_item {
        }
        .user_info_item {
          width: 100%;
          // margin-top: 20*0.6px;
          padding: 14 * 0.6px 0;
          font-size: 18 * 0.6px;
          color: #333;
          .user_info_label {
            width: 150 * 0.6px;
            display: inline-block;
            text-align: left;
          }
        }
        .user_info_btn {
          width: 110 * 0.6px;
          height: 36 * 0.6px;
          display: inline-block;
          line-height: 36 * 0.6px;
          text-align: center;
          font-size: 16 * 0.6px;
          border: 1 * 0.6px solid #e6e6e6;
          border-radius: 6 * 0.6px;
          cursor: pointer;
          &:hover {
            border: 1 * 0.6px solid #ff7391;
            color: #ff7391;
          }
        }
        .user_contro_item {
          margin-top: 10 * 0.6px;
          .icon_item {
            //   position: relative;
            display: inline-flex;
            width: 200 * 0.6px;
            height: 110 * 0.6px;

            .icon {
              width: 70 * 0.6px;
              height: 70 * 0.6px;
              margin-top: 7 * 0.6px;
            }
            .icon_info {
              width: 70 * 0.6px;
              padding-left: 10 * 0.6px;
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              .icon_name {
                font-size: 16 * 0.6px;
                margin-top: 5 * 0.6px;
                margin-bottom: 15 * 0.6px;
              }
              .bind_btn {
                width: 75 * 0.6px;
                height: 26 * 0.6px;
                font-size: 13 * 0.6px;
                padding: 3 * 0.6px 5 * 0.6px;
                background: none;
                outline: none;
                border: 1 * 0.6px solid #e6e6e6;
                border-radius: 5 * 0.6px;
                color: #4ea4dc;
                line-height: 20 * 0.6px;
                cursor: pointer;
              }
              .bind_btn_yes {
                &:hover {
                  border: 1 * 0.6px solid #ff7391;
                  color: #ff7391;
                }
              }
              .bind_btn_no {
                background-color: #ccc;
                cursor: not-allowed;
              }
              // position: absolute;
              // top: 0;
              // left: 75*0.6px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .usercenter {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #f1f1f1;
    .w1 {
      width: 100%;
      margin: 0 auto;
    }
    .user_header {
      width: 100%;
      height: 45px;
      // background: #ff7391;
      background: #fff;
      .ft_logo {
        width: 166 * 0.6px;
        height: 66 * 0.6px;
        background: url("../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
      }
      .user_name {
        width: 210 * 0.6px;
        height: 66 * 0.6px;
        padding: 0 * 0.5px 10 * 0.5px;
        //   box-sizing: border-box;
        margin-top: -66 * 0.5px;
        line-height: 80 * 0.5px;
        font-size: 20 * 0.5px;
        color: #fff;
        text-align: center;
      }
      .name {
        width: 100%;
        height: 100%;
        position: relative;
        .ftrenwu_icon {
          float: left;
          font-size: 25 * 0.5px;
          color: #ccc;
          margin-left: 10 * 0.5px;
          width: 45 * 0.5px;
          margin-top: 15 * 0.5px;
        }
        .userName {
          display: inline-block;
          width: 90 * 0.5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333;
        }
        .loginOut {
          float: right;
          color: #333;
          font-size: 25 * 0.5px;
          cursor: pointer;
          margin-right: 10 * 0.5px;
        }
        .login_out {
          width: 210 * 0.5px;
          height: 50 * 0.5px;
          position: absolute;
          background: #fff;
          line-height: 50 * 0.5px;
          top: 66 * 0.5px;
          display: none;
        }
        &:hover {
          border-bottom: 5 * 0.5px;
          .login_out {
            display: block;
            color: #000;
          }
        }
      }
    }
    .userInfo {
      width: 380px;
      margin: 0 auto;
      margin-top: 50 * 0.5px;
      .Info_item {
        background: #fff;
        min-height: 300 * 0.5px;
        border: 1 * 0.5px solid #ccc;
        border-radius: 10 * 0.5px;
        padding: 0 30 * 0.5px;
        .title {
          height: 60 * 0.5px;
          font-size: 20 * 0.5px;
          border-bottom: 1 * 0.5px solid #e6e6e6;
          line-height: 60 * 0.5px;
          margin-bottom: 20 * 0.5px;
        }
        .title_item {
        }
        .user_info_item {
          width: 100%;
          // margin-top: 20*0.5px;
          padding: 14 * 0.5px 0;
          font-size: 18 * 0.5px;
          color: #333;
          .user_info_label {
            width: 150 * 0.5px;
            display: inline-block;
            text-align: left;
          }
        }
        .user_info_btn {
          width: 110 * 0.5px;
          height: 36 * 0.5px;
          display: inline-block;
          line-height: 36 * 0.5px;
          text-align: center;
          font-size: 16 * 0.5px;
          border: 1 * 0.5px solid #e6e6e6;
          border-radius: 6 * 0.5px;
          cursor: pointer;
          &:hover {
            border: 1 * 0.5px solid #ff7391;
            color: #ff7391;
          }
        }
        .user_contro_item {
          margin-top: 10 * 0.75px;
          .icon_item {
            //   position: relative;
            display: inline-flex;
            width: 200 * 0.75px;
            height: 110 * 0.75px;

            .icon {
              width: 70 * 0.75px;
              height: 70 * 0.75px;
              margin-top: 7 * 0.75px;
            }
            .icon_info {
              width: 70 * 0.75px;
              padding-left: 10 * 0.75px;
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              .icon_name {
                font-size: 16 * 0.75px;
                margin-top: 5 * 0.75px;
                margin-bottom: 15 * 0.75px;
              }
              .bind_btn {
                width: 75 * 0.75px;
                height: 26 * 0.75px;
                font-size: 13 * 0.75px;
                padding: 3 * 0.75px 5 * 0.75px;
                background: none;
                outline: none;
                border: 1 * 0.75px solid #e6e6e6;
                border-radius: 5 * 0.75px;
                color: #4ea4dc;
                line-height: 20 * 0.75px;
                cursor: pointer;
              }
              .bind_btn_yes {
                &:hover {
                  border: 1 * 0.5px solid #ff7391;
                  color: #ff7391;
                }
              }
              .bind_btn_no {
                background-color: #ccc;
                cursor: not-allowed;
              }
              // position: absolute;
              // top: 0;
              // left: 75*0.5px;
            }
          }
        }
      }
    }
  }
}
</style>
